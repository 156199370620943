import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { setLocalIp } from '../interceptor/apiInterceptor';
type IPContextType = string;
// Create the context
const IPContext = createContext<IPContextType>('');

// Create a provider component
export const IPProvider = ({ children }: { children: ReactNode }) => {
  const [ip, setIP] = useState<string>('');

  useEffect(() => {
    // Fetch public IP address only once
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        setIP(data.ip);
        if (data.ip) {
          setLocalIp(data.ip);
        }
      })
      .catch((err) => console.error('Failed to get IP address:', err));
  }, []);
  return <IPContext.Provider value={ip}>{children}</IPContext.Provider>;
};

// Create a custom hook to use the IP context
export const useIP = () => {
  return useContext(IPContext);
};