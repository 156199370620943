import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { images } from "../../../constants";
import SidebarContext from "../../../store/sidebarContext";
import classes from "./Sidebar.module.scss";
import { Icon, Tooltip } from "@mui/material";
import config from "../../../constants/config";
import { ISideBarNav } from "../../../interfaces/configInterface";
import useLogout from "../../../services/logoutServiceCall";
import { useDevice } from "../../../store/deviceContext";
import { useModulePrivilage } from "../../../store/modulePrivilageContext";
import { UserRole, WhitelabelModuleNames, WhitelabelType } from "../../../utils/enums";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
// import ThemeBox from "../topnav/rightBox/themeBox/ThemeBox";
// import FullScreen from "../topnav/rightBox/fullScreen/FullScreen";
// import { toggleFullScreen } from "../../../utils/utils";
// import { Icon } from "@iconify/react";
// import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
function Sidebar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const { isDesktop, isIPad, isMobile, isLandscape } = useDevice();
  const { width } = useWindowSize();
  const location = useLocation();
  const sidebarCtx = useContext(SidebarContext);
  const { moduleList, moduleListWL } = useModulePrivilage();
  const { t } = useTranslation();
  const { logOutServiceCall } = useLogout();
  let sidebarNav: ISideBarNav[] = [];
  if (process.env.REACT_APP_ADMIN_APP_NAME === "owner") {
    sidebarNav = updatedOwnerSideBarNavList(
      config.roleBasedConfig["owner"].sidebarNav
    );
  } else {
    sidebarNav = updatedWLSideBarNavList(
      config.roleBasedConfig["whitelabel"].sidebarNav
    );
  }

  /** Update side bar options for Owner as per roles getting from server */
  function updatedOwnerSideBarNavList(
    existingList: ISideBarNav[]
  ): ISideBarNav[] {
    // if (!!userRole) {
    //   updatedSideBarList = existingList.filter(element => element.roleToShow.indexOf(userRole) !== -1);
    // }
    let sidebarNavArr: ISideBarNav[] = [];
    if (moduleList && moduleList.length > 0) {
      sidebarNavArr = existingList.filter(
        (sidebarItem, index) =>
          index === 0 ||
          moduleList.some(
            (serverItem: any) => serverItem.name === sidebarItem.moduleNameInDB
          )
      );
    }
    return sidebarNavArr;
  }

  /** Update side bar options for W/L subuser as per roles getting from server */
  function updatedWLSubUserSideBarNavList(existingList: ISideBarNav[]): ISideBarNav[] {
    let sidebarNavArr: ISideBarNav[] = [];
    // remove Panel Role Module, Subuser Role Panel, Subuser Listing & Panel User List if its still coming from server by mistaken
    const modulesToRemoved = [WhitelabelModuleNames.SUBUSER_ROLE_PANEL, WhitelabelModuleNames.SUBUSER_LISTING, WhitelabelModuleNames.PANEL_ROLE_MODULE, WhitelabelModuleNames.PANEL_USER_LIST];
    const updatedModuleList = moduleListWL.filter(item => !modulesToRemoved.includes(item));
    if (updatedModuleList && updatedModuleList.length > 0) {
      sidebarNavArr = existingList.filter(
        (sidebarItem, index) =>
          index === 0 ||
          updatedModuleList.some(
            (serverItem: string) => serverItem === sidebarItem.moduleNameInDB
          )
      );
    }
    return sidebarNavArr;
  }

  // Method to modify side bar list as per condition typeToShow added in config
  function updatedWLSideBarNavList(existingList: ISideBarNav[]): ISideBarNav[] {
    let updatedWLList: ISideBarNav[] = [];
    let updatedSideBarList: ISideBarNav[] = [];
    const userRole = localStorage.getItem('role');
    const wlType: string = localStorage.getItem('wlType') || '';
    if (wlType === WhitelabelType.B2B || wlType === WhitelabelType.B2C) {
      updatedWLList = existingList.filter(
        (element) => element.typeToShow.indexOf(wlType) !== -1
      );
    }
    if (!!userRole) {
      if (userRole === UserRole.SUBUSER) {
        updatedSideBarList = updatedWLSubUserSideBarNavList(updatedWLList);
      } else {
        updatedSideBarList = updatedWLList.filter(
          (element) => element.roleToShow.indexOf(userRole) !== -1
        );
      }
    }
    return updatedSideBarList;
  }

  function openSidebarHandler() {
    //for width>768(tablet size) if sidebar was open in width<768 was opened too.
    //just in case of tablet size and smaller then, sidebar__open can added.
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div
      className={`${classes.sidebar} ${
        !sidebarCtx.isOpen && classes.sidebar_close
      }`}
    >
      {isDesktop || isIPad || (isMobile && isLandscape) ? (
        <div
          className={classes.sidebar__logo}
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        >
          {sidebarCtx.isOpen ? (
            <img
              className={classes.logoDesktop}
              src={images.logo}
              alt="jontech"
            />
          ) : (
            <img
              className={classes.logoMobile}
              src={images.logoMobile}
              alt="jontech"
            />
          )}
        </div>
      ) : (
        <div
          className={classes.sidebar__logo}
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            className={classes.logoDesktop}
            src={images.logo}
            alt="jontech"
          />
        </div>
      )}

      <div className={classes.sidebar__menu}>
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`${classes.sidebar__menu__item} ${
              activeIndex === index && classes.active
            }`}
            onClick={openSidebarHandler}
          >
            <div className={classes.sidebar__menu__item__icon}>
              <Tooltip title={nav.text} placement="right-start">
                <Icon component={nav.icon} />
                {/* {images[nav.icon as keyof typeof images] ? (
                  <img
                    src={images[nav.icon as keyof typeof images]}
                    alt={nav.text}
                  />
                ) : (
                  <Icon icon={nav.icon} />
                )} */}
              </Tooltip>
            </div>
            <div className={classes.sidebar__menu__item__txt}>
              {t(nav.section)}
            </div>
          </Link>
        ))}

        {/* For mobile responsive code */}
        {/* {!isDesktop && isPortrait && !isIPad && (
          <>
            <div className={classes.sidebar__menu__item}>
              <div className={classes.sidebar__menu__item__icon}>
                <ContrastRoundedIcon />
              </div>
              <div
                style={{ cursor: "pointer" }}
                className={classes.sidebar__menu__item__txt}
              >
                {t("Theme")}
              </div>
              <div style={{ marginLeft: "1.5rem" }}>
                <ThemeBox />
              </div>
            </div>
            <div className={classes.sidebar__menu__item}>
              <div className={classes.sidebar__menu__item__icon}>
                <FullScreen />
              </div>
              <button
                onClick={toggleFullScreen}
                className={`${classes.sidebar__menu__item__txt} ${classes.borderlessBtn}`}
              >
                {t("Full Screen")}
              </button>
            </div>
          </>
        )} */}
        <Link
          to="/login"
          className={classes.sidebar__menu__item}
          onClick={logOutServiceCall}
        >
          <div className={classes.sidebar__menu__item__icon}>
            <Tooltip title={'Logout'} placement="right-start">
              <LogoutRoundedIcon/>
              {/* <img src={images.logout_icon} alt={"logout"} /> */}
              {/* <Icon icon="tabler:logout" /> logout_icon*/}
            </Tooltip>
          </div>
          <div className={classes.sidebar__menu__item__txt}>{t("logout")}</div>
        </Link>
      </div>

    </div>
  );
}

export default Sidebar;
