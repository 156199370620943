const images = {
  logo: require("../assets/images/project-logo.png"),
  logoMobile: require("../assets/images/project-logo-mobile.png"),
  avt: require("../assets/images/avatar.avif"),
  owner_avt: require("../assets/images/owner_avatar.avif"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  dashboard: require("../assets/images/Revenue-cuate.svg"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
  fullScreen_icon: require("../assets/images/full-screen.png"),
  OtpVerificationn: require("../assets/images/otp-verification.png"),
  two_factor_auth_demo: require("../assets/images/2fa_verification.gif"),
  bg: require("../assets/images/bg.png"),
};

export default images;