import { createContext, useState, useContext, ReactNode } from 'react';
import { getApiUrlEndpointAsPerRole } from '../utils/utils';
import getService from '../services/getService';
import { IQTProviderOptions } from '../interfaces/interfaceCommon';

interface FeatureProviderContextProps {
  qtProviderList: IQTProviderOptions[];
  gapProviderList: IQTProviderOptions[];
  fetchQTproviderList: () => void;
  fetchGapProviderList: () => void;
}

const FeatureProviderContext = createContext<FeatureProviderContextProps | undefined>(undefined);

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const [qtProviderList, setQtProviderList] = useState<IQTProviderOptions[]>([]);
  const [gapProviderList, setGapProviderList] = useState<IQTProviderOptions[]>([]);
  const [qtListFallbackCounter, setQtListFallbackCounter] = useState<number>(0);
  const [gapListFallbackCounter, setGapListFallbackCounter] = useState<number>(0);

  const fetchQTproviderList = async () => {
    if(qtProviderList.length === 0) {
      try {
        const url = getApiUrlEndpointAsPerRole().qtProviderList;
        const result: any = await getService(url);
        if (result.qtProviderList) {
          const sortedData = result.qtProviderList.sort((a:any, b:any) => {
            return a.name.localeCompare(b.name);
          });
          setQtProviderList(sortedData);
        } else {
          console.error('API response data structure is unexpected:', result);
        }
      } catch (error) {
        if(qtListFallbackCounter <= 2) {
          setQtListFallbackCounter((prevCounter) => prevCounter + 1);
          fetchQTproviderList();
        }
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetchGapProviderList = async () => {
    if(gapProviderList.length === 0) {
      try {
        const url = getApiUrlEndpointAsPerRole().gapProviderList;
        const result: any = await getService(url);
        if (result.gapProviderList) {
          const transformedArray = result.gapProviderList.map((item:any, index:number) => ({
            id: item.providerName,
            name: item.providerName
          }));
          const sortedData = transformedArray.sort((a:any, b:any) => {
            return a.name.localeCompare(b.name);
          });
          setGapProviderList(sortedData);
        } else {
          console.error('API response data structure is unexpected:', result);
        }
      } catch (error) {
        if(gapListFallbackCounter <= 2) {
          setGapListFallbackCounter((prevCounter) => prevCounter + 1);
          fetchGapProviderList();
        }
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <FeatureProviderContext.Provider value={{ qtProviderList, gapProviderList, fetchQTproviderList, fetchGapProviderList }}>
      {children}
    </FeatureProviderContext.Provider>
  );
};

export const useFeatureProvider = () => {
  const context = useContext(FeatureProviderContext);
  if (!context) {
    throw new Error('useFeatureProvider must be used within a FeatureProvider');
  }
  return context;
};

export default FeatureProviderContext;