import { FormikHelpers, useFormik } from "formik";
import images from "../../../constants/images";
import classes from "../login/LoginBox.module.scss";
import { useContext, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import * as Yup from 'yup';
import { useDevice } from "../../../store/deviceContext";
import { useMutation } from "react-query";
import { getApiUrlEndpointAsPerRole, setLocalStorageData } from "../../../utils/utils";
import postService from "../../../services/post";
import { useLocation, useNavigate } from "react-router-dom";
import { OwnerRoutes, UserRole } from "../../../utils/enums";
import toast from "react-hot-toast";
import LoginContext from "../../../store/loginContext";
import BalanceContext from "../../../store/balanceContext";
import ModulePrivilageContext from "../../../store/modulePrivilageContext";
import { useIP } from "../../../store/IpContext";

interface IOTPValues {
    otp1: string;
    otp2: string;
    otp3: string;
    otp4: string;
    otp5: string;
    otp6: string;
}

interface IVerifyOTP {
    OTP: string;
    userId: number;
    role: string;
    clientIP: string;
}

export const Otp = () => {
    const navigate = useNavigate();
    const loginCtx = useContext(LoginContext);
    const balanceCtx = useContext(BalanceContext);
    const modulePrivilageCtx = useContext(ModulePrivilageContext);
    const { isMobile, isLandscape } = useDevice();
    const ip = useIP();
    const location = useLocation();
    const userId: number = Number(location.state?.userData?.id);
    const role: string = location.state?.userData?.role;

    const inputRefs = {
        otp1: useRef<HTMLInputElement | null>(null),
        otp2: useRef<HTMLInputElement | null>(null),
        otp3: useRef<HTMLInputElement | null>(null),
        otp4: useRef<HTMLInputElement | null>(null),
        otp5: useRef<HTMLInputElement | null>(null),
        otp6: useRef<HTMLInputElement | null>(null),
    };

    const formik = useFormik<IOTPValues>({
        initialValues: {
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
            otp6: ''
        },
        validationSchema: Yup.object({
            otp1: Yup.string().required('Required'),
            otp2: Yup.string().required('Required'),
            otp3: Yup.string().required('Required'),
            otp4: Yup.string().required('Required'),
            otp5: Yup.string().required('Required'),
            otp6: Yup.string().required('Required'),
        }),
        onSubmit: (values: IOTPValues, formikHelpers: FormikHelpers<IOTPValues>) => {
            const param: IVerifyOTP = {
                OTP: Object.values(values).join(''),
                userId: userId,
                role: role,
                clientIP: ip
            }
            mutation.mutate(param);
            formikHelpers.resetForm();
        }
    });

    // Focus on the first input when the component mounts
    useEffect(() => {
        if (inputRefs.otp1 && inputRefs.otp1.current) {
            inputRefs.otp1.current.focus();
        }
    }, []);


    const mutation = useMutation({
        mutationFn: async (param: IVerifyOTP) => {
          try {
            const apiEndpoint = getApiUrlEndpointAsPerRole();
            const url = apiEndpoint.verify2FA_OTP;
            const result = await postService(url, param);
            return result;
          } catch (error) {
            throw error;
          }
        },
        onSuccess: (data) => {
            toast.success('Login successful!');
            setLocalStorageData(data.data);
            loginCtx.toggleLogin();
            if (data.data.rest) {
                loginCtx.setDataOnLogin(data.data.rest);
            } else {
                loginCtx.setDataOnLogin(data.data);
            }
            balanceCtx?.fetchBalance();//Call for the 1st time to fetch balance once user login
            balanceCtx?.addBalanceInterval();
            if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner'){
                modulePrivilageCtx?.fetchModulesNPrivilage();
            }else if(localStorage.getItem('role') === UserRole.SUBUSER) {
                modulePrivilageCtx?.fetchModulesNPrivilage();  
            }
            navigate(OwnerRoutes.routeDashboard);
        },
        onError: (error: any) => {
            if (inputRefs.otp1 && inputRefs.otp1.current) {
                inputRefs.otp1.current.focus();
            }
            toast.error('Authentication failed: ' + error.response?.data?.message);
        },
    });

    const handleChange = (e: any, nextInput: HTMLInputElement | null) => {
        const { name, value } = e.target;
        // Only set the first character (to prevent multiple numbers in the same field)
        const trimmedValue = value.slice(0, 1);
        formik.setFieldValue(name, trimmedValue);
        if (trimmedValue.length === 1 && nextInput) {
          nextInput.focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        const inputElement = e.target as HTMLInputElement;
        if (e.key === 'Backspace') {
          const { name } = inputElement;
          const value = formik.values[name as keyof typeof formik.values];
    
          if (value === '') {
            const prevInput = inputRefs[`otp${currentIndex}` as keyof typeof inputRefs];
            if (prevInput) {
              prevInput.current?.focus();
              formik.setFieldValue(`otp${currentIndex}`, '');
            }
          } else {
            // Clear the current input if there's still a value in it
            formik.setFieldValue(name, '');
          }
        }
    };
   
    return (
        <div className={`${classes.container}`}>
            <img src={images.bg}  className={classes.fullscreenBg} alt="" />
            <div className={classes.innerWrapper}>
                <div className={classes.wrapper}>
                    <div className={classes.loginBoxWrapper}>
                        {/* <img src={images.rightBottom} className={classes.loginRightBg} alt="rightBottom"/>
                        <img src={images.leftTop} className={classes.loginRingLeft} alt="leftTop"/>
                        <img src={images.rightTop} className={classes.rightTop} alt="rightTop"/> */}
                        <div className={classes.loginBox} style={isMobile && isLandscape ? {scale: '0.65'} : {scale: '1'}}>
                            <div className={classes.logoWrapper}>
                                <img className={classes.logo} src={images.logo} alt="jontech" />
                            </div>
                            <div className={classes.otpTopContainer}>
                                <img src={images.OtpVerificationn} alt="otp-verification" />
                                <div className={classes.content}>{"Two-factor Authentication"}</div>
                                <div className={classes.textContent}>{"Enter the code generated by your Authenticator app"}</div>
                                {/* <div className={classes.textContent}>{"We have sent you 4 digit code to your email or phone"}</div> */}
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} justifyContent="center">
                                    {['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'].map((fieldName, index) => (
                                        <Grid item key={fieldName}>
                                        <TextField
                                            name={fieldName}
                                            value={formik.values[fieldName as keyof typeof formik.values]}
                                            onChange={(e) => handleChange(e, inputRefs[`otp${index + 2}` as keyof typeof inputRefs]?.current)}
                                            onBlur={formik.handleBlur}
                                            onKeyDown={(e) => handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, index)}
                                            inputRef={inputRefs[fieldName as keyof typeof inputRefs]}
                                            variant="outlined"
                                            inputProps={{ maxLength: 1, type: 'text', pattern: '[0-9]*' }}  // Restrict to a single numeric character
                                            className={classes.otpInput}
                                        />
                                        </Grid>
                                    ))}
                                </Grid>
                                {/* <div className={classes.resendOtp}>
                                    {"Didn’t received the code? "}
                                    <span role="button">Resend code</span>
                                </div> */}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!(formik.isValid && formik.dirty)}
                                    style={{ marginTop: '20px', color: "#fff", marginBottom: "20px" }}
                                    sx={{
                                        "&.Mui-disabled": {
                                          color: "var(--text-color) !important",
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={classes.rightBlur} ></div> */}
            {/* <div className={classes.loginRightImage}>
                <img src={images.loginRightImage} className={classes.loginRightImage} alt="loginRightImage"/>
            </div> */}
        </div >
    )
};
