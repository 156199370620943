import { Tooltip } from "@mui/material";
import { toggleFullScreen } from "../../../../../utils/utils";
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
// import { images } from "../../../../../constants";
// import { useDevice } from "../../../../../store/deviceContext";
function FullScreen() {
  // const { isMobile, isPortrait } = useDevice();
  return (
    <Tooltip title="Full Screen">
      <div onClick={toggleFullScreen}>
        <div>
          {/* <img src={images.fullScreen_icon} alt={'fullscreen'} style={{width: '1.4rem', height: '1.4rem'}} />		 */}
          <OpenInFullRoundedIcon fontSize="large" 
            style={{
              position: "relative",
              top: "1.5px", 
              color: "var(--primaryColor)"//(isMobile && isPortrait) ? "none" : "var(--primaryColor)"
            }}/>
        </div>
      </div>
    </Tooltip>
  );
}

export default FullScreen;