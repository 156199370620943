import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { Button, IconButton, InputAdornment, Stack } from "@mui/material";
import { IChangePassword } from "../../../interfaces/interfaceCommon";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../../validations/whitelabel/changePassword";
import { InputBoxWithIcon } from "../../common/UI/inputBox/InputBoxWithIcon";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getApiUrlEndpointAsPerRole } from "../../../utils/utils";
import postService from "../../../services/post";

function ChangePasswordBox() {
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);  
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const mutation = useMutation({
    mutationFn: async (param: IChangePassword) => {
      try {
          const apiEndpoint = getApiUrlEndpointAsPerRole();
          const url = apiEndpoint.changePasswordApiURL;
          const result = await postService(url, param);
          return result;
      } catch (error) {
          throw error;
      }
    },  
    onSuccess: (data) => {
      toast.success('Password changed!');
      queryClient.invalidateQueries('getWhiteLabelList');
    },
    onError: (error: any) => {
      toast.error('Password change failed: '+ error.response?.data?.message);
    },
  });

  const initialValues = {
    old_password: "",
    new_password: "",
    confirmPassword: "",
  }

  const { values, errors, handleBlur, handleSubmit, handleChange, touched, isValid, dirty, resetForm} = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      let param: IChangePassword = {
        oldPassword: values.old_password,
        password: values.new_password
      }
      mutation.mutate(param);
      action.resetForm();
    },
    validationSchema: changePasswordSchema
  });

  const handlePassword = () => {
    setShowPassword((show) => !show);
  }

  const endAdornmentForOldPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handlePassword}
          edge="end"
          disabled={!values.old_password}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  const handleNewPassword = () => {
    setShowNewPassword((show) => !show);
  }

  const endAdornmentForNewPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleNewPassword}
          edge="end"
          disabled={!values.old_password}
        >
          {showNewPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  const handleConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  }

  const endAdornmentForConfirmPassword = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleConfirmPassword}
          edge="end"
          disabled={!values.confirmPassword}
        >
          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <div style={{marginTop:'28px'}}> 
      <form onSubmit={handleSubmit}>
        <Stack marginBottom={2}>
          <InputBoxWithIcon
            endAdornment={endAdornmentForOldPassword}
            onChangeHandler={handleChange}
            onBlurHandler={handleBlur}
            value={values.old_password}
            name="old_password"
            id={"old_password"}
            label="Old Password"
            variant="outlined"
            errorMessage={errors.old_password}
            isTouched={touched.old_password}
            type={showPassword ? "text" : "password"}
          /> 
        </Stack>
        <Stack marginBottom={2}>
          <InputBoxWithIcon
            endAdornment={endAdornmentForNewPassword}
            onChangeHandler={handleChange}
            onBlurHandler={handleBlur}
            value={values.new_password}
            name="new_password"
            id={"new_password"}
            label="New Password"
            variant="outlined"
            errorMessage={errors.new_password}
            isTouched={touched.new_password}
            type={showNewPassword ? "text" : "password"}
          /> 
        </Stack>
        <Stack marginBottom={2}>
          <InputBoxWithIcon
            onChangeHandler={handleChange}
            onBlurHandler={handleBlur}
            value={values.confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            label={("Confirm password")}
            variant="outlined"
            errorMessage={errors.confirmPassword}
            isTouched={touched.confirmPassword}
            endAdornment={endAdornmentForConfirmPassword}
            type={showConfirmPassword ? "text" : "password"}
          />
        </Stack>

        <div className={`button_Wrapper`}>
          <Button className="customBtn" disabled={!dirty} type="reset" onClick={() => resetForm()} size="small" variant="outlined">
            {"Clear"}
          </Button>
          <Button className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" style={{color:'#FFFFFF'}} variant="contained">
            {"Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordBox;