import { TextField } from "@mui/material";
import { IInputBoxProps } from "../../../../interfaces/inputBox";
import { FormError } from "../../FormError/FormError";
import { shouldShowError } from "../../../../utils/utils";
import { useState } from "react";
import { toWords } from "number-to-words";

export const InputBox: React.FunctionComponent<IInputBoxProps> = ({ onChangeHandler, onBlurHandler, value, label, variant, name, id, errorMessage, isTouched, startAdornment, type, disabled, maxRows, multiline, showAmountText }) => {
    const [amountInWords, setAmountInWords] = useState("");
    const handleChange = (e:any) => {
        const numericValue = e.target.value;
        // Call the existing onChangeHandler
        if (onChangeHandler) {
            onChangeHandler(e);
        }
        // Convert to words if it's a number
        if (!isNaN(numericValue) && numericValue !== "" && showAmountText &&  numericValue<=1000000000000000) {
            const words = toWords(Number(numericValue));
            setAmountInWords(words.charAt(0).toUpperCase() + words.slice(1));
        } else {
            setAmountInWords("");
        }
    };

    return (
        <div>
            <TextField
                onChange={handleChange}
                onBlur={onBlurHandler ? onBlurHandler : () => { }}
                value={value}
                name={name}
                fullWidth
                maxRows={maxRows}
                multiline={multiline}
                id={id}
                label={label}
                variant={variant}
                InputProps={{
                    startAdornment: startAdornment ? startAdornment : null,
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Custom border-radius for the input field
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px", // Custom border-radius for the outline
                    },
                }}
                type={type || "text"}
                className="inputBox"
                disabled={disabled}
                autoComplete="off"
            />
            {/* Display error message */}
            {errorMessage && <div>
                {shouldShowError(errorMessage, isTouched) && <FormError error={errorMessage} />}
            </div>}
            
            {/* Display the amount in words */}
            {amountInWords && <div style={{fontSize: '0.65rem', marginLeft:'6px'}}>{`${amountInWords} rupees`}</div>}
        </div>
    );
}