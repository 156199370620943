import axios from "axios";
import { apiFailureRedirectHome } from "../utils/utils";

let localIp: string | null = null;
export const setLocalIp = (ip: string) => {
  localIp = ip;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_LINK,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers": "client-ip"
  },
});

axiosInstance.interceptors.request.use(
  async (config:any) => {
    const token = localStorage.getItem('token');
    if (localIp) {
      config.headers['client-ip'] = localIp; //config.headers['X-Local-IP'] = localIp;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Global counter to track the number of consecutive errors
let errorCounter = 0;
axiosInstance.interceptors.response.use(
  (response) => {
    // Reset error counter on a successful response
    errorCounter = 0;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Ensure originalRequest._retry is initialized if not set
    if (!originalRequest.hasOwnProperty('_retry')) {
      originalRequest._retry = false;
    }
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Increment the error counter
      errorCounter++;
      // If the error occurs 3 times, log out
      if (errorCounter >= 3) {
        apiFailureRedirectHome(); // Call your logout method here
      }
      const refreshToken = localStorage.getItem('refreshToken');
      if(refreshToken) {
        try {
          const response = await axiosInstance.post('/api/v1/auth/refresh-token', {
            refreshToken: refreshToken,
          });  
          const newAccessToken = response.data.data.accessToken;
          const newRefreshToken = response.data.data.refreshToken;
          localStorage.setItem('token', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          apiFailureRedirectHome();
          return Promise.reject(refreshError);
        }
      }else {
        apiFailureRedirectHome();
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;