import React, { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/common/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";
import config from "./constants/config";
import LoginContext from "./store/loginContext";
import { useModulePrivilage } from "./store/modulePrivilageContext";
import { OwnerModuleNames, UserRole, WhitelabelModuleNames, WhitelabelType } from "./utils/enums";
// import { Otp } from "./components/common/otp/Otp";
import ScrollToTop from "./components/common/scrollToTop/ScrollToTop";
import { Otp } from "./components/common/otp/Otp";

const Login = React.lazy(() => import("./pages/common/Login"));
const Dashboard = React.lazy(() => import("./pages/common/Dashboard"));
const Transaction = React.lazy(() => import("./pages/common/Transaction"));
const NotFound = React.lazy(() => import("./pages/common/NotFound"));
const Profile = React.lazy(() => import("./pages/whitelabel/Profile"));

if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
  var AddSubowner = React.lazy(() => import("./pages/owner/AddSubowner"));
  var WhiteLabelList = React.lazy(() => import("./pages/owner/WhiteLabelList"));
  var AdminRoleManagement = React.lazy(() => import("./pages/owner/AdminRoleManagement"));
  var FeatureControl = React.lazy(() => import("./pages/owner/FeatureControl"));
  var SettlementPage = React.lazy(() => import("./pages/owner/SettlementPage"));
  var PaymentTransferPage = React.lazy(() => import("./pages/owner/PaymentMethodsTransfer"));
}else {
  var WhiteLabelUserList = React.lazy(() => import("./pages/whitelabel/WhiteLabelUserList"));
  var CreateSublevelPage = React.lazy(() => import("./pages/whitelabel/CreateSublevelPage"));
  var PaymentMethod = React.lazy(() => import("./pages/whitelabel/PaymentMethod"));
  var PanelUserListPage = React.lazy(() => import("./pages/whitelabel/PanelUserListPage"));
  var PanelRoleManagement = React.lazy(() => import("./pages/whitelabel/PanelRoleManagement"));
  var BankDetailPage = React.lazy(() => import("./pages/whitelabel/BankDetailPage"));
  var GlobalSearchPage = React.lazy(() => import("./pages/whitelabel/GlobalSearchPage"));
  var SubUserPage = React.lazy(() => import("./pages/whitelabel/SubUserPage"));
  var SubUserRolePanelPage = React.lazy(() => import("./pages/whitelabel/SubUserRolePanelPage"));
  var SettlementWLPage = React.lazy(() => import("./pages/whitelabel/SettlementWLPage"));
  var ClientPl = React.lazy(() => import("./pages/whitelabel/ClientPl"));
  var BetTickerPage = React.lazy(() => import("./pages/whitelabel/BetTickerPage"));
  var RunningAnalysisPage = React.lazy(() => import("./pages/whitelabel/RunningAnalysisPage"));
  var MarketAnalysisPage = React.lazy(() => import("./pages/whitelabel/MarketAnalysisPage"));
}

// const PermissionListPage = React.lazy(() => import("./pages/owner/PermissionList"));
// const WhiteLabelEdit = React.lazy(() => import("./pages/owner/WhiteLabelEdit"));
// const BlankPage = React.lazy(() => import("./pages/BlankPage"));
// const Customers = React.lazy(() => import("./pages/common/Customers"));
// const CustomerEdit = React.lazy(() => import("./pages/common/CustomerEdit"));

function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const loginCtx = useContext(LoginContext);
  let { moduleList, moduleListWL } = useModulePrivilage();
  let routesConstatnts:any;
  if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
    routesConstatnts = config.roleBasedConfig['owner'].routesConstatnts;
  }else {
    routesConstatnts = config.roleBasedConfig['whitelabel'].routesConstatnts;
  }

  document.addEventListener('contextmenu', function(event) {
    event.preventDefault();
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppReady(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [moduleList, moduleListWL]); 

  // Function to render the Owner routes based on module name received from server
  const renderOwnerRoutes = (moduleName: string) => {
    switch (moduleName) {
      case OwnerModuleNames.ADD_SUBOWNER:
        return <Route path={routesConstatnts.routeAddSubowner} element={<AddSubowner />} />;
      case OwnerModuleNames.WHITELABEL_LIST:
        return <Route path={routesConstatnts.routeWhiteLabelList} element={<WhiteLabelList />} />;
      case OwnerModuleNames.ACCOUNT_STATEMENT:
        return <Route path={routesConstatnts.routeTransaction} element={<Transaction />} />;
      case OwnerModuleNames.ADMIN_ROLE:
        return <Route path={routesConstatnts.routeAdminRole} element={<AdminRoleManagement />} />;
      case OwnerModuleNames.PAYMENT_METHODS:
        return <Route path={routesConstatnts.paymentTransfer} element={<PaymentTransferPage />} />;
      case OwnerModuleNames.FEATURE_CONTROL:
        return <Route path={routesConstatnts.routeFeatureControl} element={<FeatureControl />} />;
      case OwnerModuleNames.SETTLEMENT_FEATURE:
        return <Route path={routesConstatnts.routeSettlement} element={<SettlementPage />} />;
      // case "Navigation":
      //   return <Route path={routesConstatnts.permissionList} element={<PermissionListPage />} />;
      default:
        return null;
    }
  };

  // Function to render the W/L Subuser routes based on module name received from server
  const renderWLRoutes = (moduleName: string) => {
    // Check condition if subuser login then if bymistaken subuser panel is assigned from backend then also not to show him subuser panel
    if((moduleName === WhitelabelModuleNames.SUBUSER_LISTING || moduleName === WhitelabelModuleNames.SUBUSER_ROLE_PANEL) && localStorage.getItem('role') === UserRole.SUBUSER) {
      return null;
    } else {
      switch (moduleName) {
        case WhitelabelModuleNames.TRANSACTION:
          return <Route path={routesConstatnts.routeTransaction} element={<Transaction />} />;
        case WhitelabelModuleNames.SUBLEVEL_LISTING:
          return <Route path={routesConstatnts.routeWhiteLabelUserList} element={<WhiteLabelUserList />} />;
        case WhitelabelModuleNames.GLOBAL_SEARCH:
          return <Route path={routesConstatnts.routeGlobalSearch} element={<GlobalSearchPage />} />;
        case WhitelabelModuleNames.SUBUSER_LISTING:
          return <Route path={routesConstatnts.routesSubUser} element={<SubUserPage />} />;
        case WhitelabelModuleNames.SUBUSER_ROLE_PANEL:
          return <Route path={routesConstatnts.routesSubUserRolePanel} element={<SubUserRolePanelPage />} />;
        case WhitelabelModuleNames.SETTLEMENT:
          return <Route path={routesConstatnts.routesSettlement} element={<SettlementWLPage />} />
        case WhitelabelModuleNames.CLIENT_PL:
          return <Route path={routesConstatnts.routesPLReports} element={<ClientPl />} />
        case WhitelabelModuleNames.BET_TICKER:
          return <Route path={routesConstatnts.routesBetTicker} element={<BetTickerPage />} />
        case WhitelabelModuleNames.SUBLEVEL_CREATION:
          //Only to show subuser if this module is assigned to him only to give access to create sublevel but he can't see the list
          return <Route path={routesConstatnts.routeCreateSublevel} element={<CreateSublevelPage />} />
        case WhitelabelModuleNames.PAYMENT_METHOD:
          return (
            (loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C) ?
              <Route path={routesConstatnts.routePaymentMethod} element={<PaymentMethod />} />
              : null
          );
        case WhitelabelModuleNames.PANEL_USER_LIST:
          return (
            (loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C) ?
              <Route path={routesConstatnts.depositeWithdrawPanel} element={<PanelUserListPage />} />
              : null
          );
        case WhitelabelModuleNames.PANEL_ROLE_MODULE:
          return (
            (loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C) ?
              <Route path={routesConstatnts.depositeWithdrawRoleManagement} element={<PanelRoleManagement />} />
              : null
          );
        case WhitelabelModuleNames.BANK_DETAILS:
          return (
            (loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C) ?
              <Route path={routesConstatnts.routeBankDetails} element={<BankDetailPage />} />
              : null
          );
        default:
          return null;
      }
    }
  };

  const renderBasedOnCondition = (): JSX.Element => {
    if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
      return (
        <Route element={<AuthLayout />}>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={routesConstatnts.routeUserProfile} element={<Profile />} />
            {/* Dynamically render routes based on moduleList */}
            {moduleList?.length > 0 &&  moduleList.map((module) => (
              <React.Fragment key={module.id}>
                {renderOwnerRoutes(module.name)}
              </React.Fragment>
            ))}
          </Route>
        </Route>
      );  
    } else {
      if(localStorage.getItem('role') === UserRole.SUBUSER) {
        return (
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path={routesConstatnts.routeUserProfile} element={<Profile />} />
              {/* Dynamically render routes based on moduleList */}
              {moduleListWL?.length > 0 &&  moduleListWL.map((module) => (
                <React.Fragment key={module}>
                  {renderWLRoutes(module)}
                </React.Fragment>
              ))}
            </Route>
          </Route>
        ); 
      } else {
        return (
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />       
              <Route path={routesConstatnts.routeUserProfile} element={<Profile />} />
              <Route path={routesConstatnts.routeTransaction} element={<Transaction />} />
              <Route path={routesConstatnts.routeWhiteLabelUserList} element={<WhiteLabelUserList />} />
              <Route path={routesConstatnts.routeGlobalSearch} element={<GlobalSearchPage />} />
              <Route path={routesConstatnts.routesSettlement} element={<SettlementWLPage />} />
              <Route path={routesConstatnts.routesPLReports} element={<ClientPl />} />
              <Route path={routesConstatnts.depositeWithdrawPanel} element={<PanelUserListPage />} />
              <Route path={routesConstatnts.depositeWithdrawRoleManagement} element={<PanelRoleManagement />} />
              <Route path={routesConstatnts.routesRunningAnalysis} element={<RunningAnalysisPage />} />
              <Route path={routesConstatnts.routesMarketAnalysis} element={<MarketAnalysisPage />} />
              <Route path={routesConstatnts.routesBetTicker} element={<BetTickerPage />} />
              {(loginCtx.loginData?.role !== UserRole.SUBUSER || localStorage.getItem('role') !== UserRole.SUBUSER ) && 
                <Route path={routesConstatnts.routesSubUser} element={<SubUserPage />} />
              }
              {(loginCtx.loginData?.role !== UserRole.SUBUSER || localStorage.getItem('role') !== UserRole.SUBUSER ) && 
                <Route path={routesConstatnts.routesSubUserRolePanel} element={<SubUserRolePanelPage />} />
              }
              {(loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C ) && 
                <Route path={routesConstatnts.routePaymentMethod} element={<PaymentMethod />} />
              }
              {(loginCtx.loginData?.WlType === WhitelabelType.B2C || localStorage.getItem('wlType') === WhitelabelType.B2C ) && 
                <Route path={routesConstatnts.routeBankDetails} element={<BankDetailPage />} />
              }
            </Route>
          </Route>
        );
      }
    }
  };

  if (!isAppReady) {
    return <LoadingSpinner />;
  }
  
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <ScrollToTop />
        <Routes>
          {renderBasedOnCondition()}
          <Route path="/healthcheck" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path={routesConstatnts.routesOTP} element={<Otp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;