import { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import getService from '../services/getService';
import { ICommonReturnType } from '../interfaces/interfaceCommon';
import LoginContext from "../store/loginContext";
import { getApiUrlEndpointAsPerRole } from '../utils/utils';
import { OWNER_MODULE_LIST, UserRole } from '../utils/enums';
interface ModulePrivilageContextProps {
  canView: boolean;
  canAdd: boolean;
  moduleList: Array<{id:number, name:string, Panel?:string}>;
  moduleListWL: Array<string>;
  updateData: (data: any) => void;
  fetchModulesNPrivilage: () => void;
}

const ModulePrivilageContext = createContext<ModulePrivilageContextProps | undefined>(undefined);

export const ModulePrivilageProvider = ({ children }: { children: ReactNode }) => {
  const [moduleList, setModuleList] = useState<Array<{id:number, name:string, Panel?:string}>>([]);
  const loginCtx = useContext(LoginContext);
  const [canView, setCanView] = useState<boolean>(false);
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [moduleListWL, setModuleListWL] = useState<Array<string>>([]);

  useEffect(() => {
    if(loginCtx.isLogin) {
      if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner' || localStorage.getItem('role') === UserRole.SUBUSER){
        fetchModulesNPrivilage();
      }
    }
  }, []);

  const updateData = (data: any) => {
    setCanView(data?.canView);
    setCanAdd(data?.canAdd);
    setModuleListWL(data?.moduleNames);
  };

  const fetchModulesNPrivilage = async () => {
    try {
      const url = getApiUrlEndpointAsPerRole().getProfile;
      const result: ICommonReturnType = await getService(url);
      if (result?.data) {
        if(process.env.REACT_APP_ADMIN_APP_NAME === 'owner'){
          if(result?.data.isOwner) {
            setModuleList(OWNER_MODULE_LIST);
          }else {
            setModuleList(result?.data.role.permission);
          }
        }else {
          updateData(result?.data);
        }
      } else {
        console.error('API response data structure is unexpected:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setCanView(false);
      setCanAdd(false);
      setModuleList([]);
    }
  }

  return (
    <ModulePrivilageContext.Provider value={{ canView, canAdd, moduleList, moduleListWL, updateData, fetchModulesNPrivilage }}>
      {children}
    </ModulePrivilageContext.Provider>
  );
};

export const useModulePrivilage = () => {
  const context = useContext(ModulePrivilageContext);
  if (!context) {
    throw new Error('useModulePrivilage must be used within a ModulePrivilageProvider');
  }
  return context;
};

export default ModulePrivilageContext;